
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { PaymentCondition } from '../../entities/payment-condition.entity';
import paymentConditionService from '../../services/payment-condition.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class PaymentConditionListComponent extends Vue {
  public paymentConditions: PaymentCondition[] = [];

  public get headers(): any {
    const items = [
      { text: 'Descrição', value: 'description', width: '50%' },
      {
        text: 'Juros',
        value: 'interestRateFormatted',
      },
      {
        text: 'Ativo',
        value: 'activeText',
      },

      {
        text: 'Ações',
        align: 'center',
        value: 'actions',
      },
    ];

    if (this.$permissionAccess.group.isSuperAdmin) {
      items.splice(1, 0, { text: 'Empresa', value: 'company.name', width: '20%' });
    }
    return items;
  }

  public goToFormEdit(paymentCondition: PaymentCondition) {
    this.$router.push({
      name: 'payment-condition-edit',
      params: {
        id: paymentCondition.id,
      },
    });
  }

  public goToFormCreate() {
    this.$router.push({
      name: 'payment-condition-create',
    });
  }

  public async getpaymentConditions() {
    const paymentConditions = await paymentConditionService.getAllPaymentsPaginated();
    this.paymentConditions = paymentConditions.map(
      (paymentCondition: PaymentCondition) => new PaymentCondition(paymentCondition),
    );
  }

  public async backPagination() {
    if (!paymentConditionService.simplePaginationService.prevLink) return;
    const paymentConditions = await paymentConditionService.simplePaginationService.prev();
    this.paymentConditions = paymentConditions.map(
      (paymentCondition: PaymentCondition) => new PaymentCondition(paymentCondition),
    );
  }

  public async nextPagination() {
    if (!paymentConditionService.simplePaginationService.nextLink) return;
    const paymentConditions = await paymentConditionService.simplePaginationService.next();
    this.paymentConditions = paymentConditions.map(
      (paymentCondition: PaymentCondition) => new PaymentCondition(paymentCondition),
    );
  }

  public async goToFirst() {
    if (!paymentConditionService.simplePaginationService.firstLink) return;
    const paymentConditions = await paymentConditionService.simplePaginationService.goToFirst();
    this.paymentConditions = paymentConditions.map(
      (paymentCondition: PaymentCondition) => new PaymentCondition(paymentCondition),
    );
  }

  public async created() {
    this.getpaymentConditions();
  }
}
